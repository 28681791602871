

const MESSAGES = [
    {
        code: 0,
        message: "No se pudo establecer conexión con el servidor. Verifica tu conexión a internet o intenta nuevamente más tarde."
    },
    {
        code: 200,
        message: "Solicitud procesada exitosamente.",
    },
    {
        code: 201,
        message: "Recurso creado exitosamente.",
    },
    {
        code: 204,
        message: "Solicitud procesada exitosamente. No hay contenido para mostrar."
    },
    {
        code: 301,
        message: "El recurso ha sido movido permanentemente.",
    },
    {
        code: 302,
        message: "El recurso se encuentra temporalmente en otra ubicación.",
    },
    {
        code: 400,
        message: "Solicitud incorrecta. Verifica los parámetros y vuelve a intentarlo."
    },
    {
        code: 401,
        message: "No autorizado. Proporciona credenciales válidas."
    },
    {
        code: 403,
        message: "Acceso prohibido. No tienes permisos para acceder a este recurso."
    },
    {
        code: 404,
        message: "Recurso no encontrado. Verifica la URL o el ID del recurso."
    },
    {
        code: 409,
        message: "Conflicto en la solicitud. El recurso ya existe o está en uso."
    },
    {
        code: 499,
        message: "La conexión fue rechazada por el servidor o el cliente la cerró antes de recibir una respuesta."
    },
    {
        code: 500,
        message: "Error interno del servidor. Inténtalo de nuevo más tarde."
    },
    {
        code: 502,
        message: "Puerta de enlace incorrecta. El servidor no pudo obtener una respuesta válida."
    },
    {
        code: 503,
        message: "Servicio no disponible. El servidor está temporalmente sobrecargado o en mantenimiento."
    },
    {
        code: 503,
        message: "No se puede establecer una conexión con el servidor. Inténtelo de nuevo más tarde."
    },
    {
        code: 502,
        message: "Error de puerta de enlace. No se pudo establecer conexión con el servidor."
    }
]


export function returnMessageFailApi(error: any): string{
    let message = 'Comunícate con el área de soporte.';
    if(error.status == 0){
        message = `
        <div class="w-full text-center">
            Error desconocido 
            <br> 
            Comunícate con el área de soporte.
        </div>
        `
    }else{
        let codeFinded = MESSAGES.find((codeMesagge) => codeMesagge.code == error.status);

        if(codeFinded){
            message = codeFinded.message;
        }
    }

    return message;
}