import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { returnMessageFailApi } from '../../shared/helpers/api-response-message';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private _injector: Injector;

  constructor(
    injector: Injector,
  ) {
    this._injector = injector;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const clonedRequest = req.clone({
      setHeaders: {
      }
    });

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        let customError: any = error;

        customError.message = returnMessageFailApi(error);

        return throwError(customError);
      })
    );
  }
}

