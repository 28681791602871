import { NgModule, LOCALE_ID } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { AppComponent } from './app.component';
import { registerLocaleData, DatePipe } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import { Interceptor } from './core/interceptors/interceptor.interceptor';
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { AppRoutingModule } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'moment/locale/es';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEspañol } from './shared/services/paginator-espanol.service';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    MatMomentDateModule,
  ],
  providers: [
    DatePipe,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEspañol },
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})

export class AppModule {}
